<template lang="pug">
  div.card.monitor-count-card
    div.card-body
      div.container-fluid()
        div.row
          div.col
            h6.card-title
              span(data-feather="layout")
              | 監視サイトV2 (Total)
        div.row
          div.col.position-relative
            div.position-absolute.start-50.translate-middle(style="top:55%;")
              h2.monitor {{ monitor_count.total }} 
                span.inactive ({{ monitor_count.inactive.total }})
          div.col-auto
            dl
              dt 1分
              dd {{ monitor_count._1min }}&nbsp;
                span.inactive ({{ monitor_count.inactive._1min }})
              dt 10分
              dd {{ monitor_count._10min }}&nbsp;
                span.inactive ({{ monitor_count.inactive._10min }})
              dt 1時間
              dd {{ monitor_count._60min }}&nbsp;
                span.inactive ({{ monitor_count.inactive._60min }})
</template>

<script>
import feather from 'feather-icons';

export default {
  name:"MonitorV2TotalCountCard",
  data (){
    return {
      // loading:true,
      // monitor_count:{
      //   loaded:false,
      //   total:"-",
      //   _1min:"-",
      //   _10min:"-",
      //   _60min:"-",
      //   inactive:{
      //     total:"-",
      //     _1min:"-",
      //     _10min:"-",
      //     _60min:"-",
      //   }
      // }
    }
  },
  computed: {
    monitor_count (){
      const result = {
        loaded:true,
        total:0,
        _1min:0,
        _10min:0,
        _60min:0,
        inactive:{
          total:0,
          _1min:0,
          _10min:0,
          _60min:0,
        }
      };
      for(let e of this.$store.state.monitor_v2_count){
        result.total += e.total;
        result._1min += e._1min;
        result._10min += e._10min;
        result._60min += e._60min;
        result.inactive.total += e.inactive.total;
        result.inactive._1min += e.inactive._1min;
        result.inactive._10min += e.inactive._10min;
        result.inactive._60min += e.inactive._60min;
      }
      return result;
    }
  },
}
</script>

<style lang="scss" scoped>
.card.monitor-count-card {
  width:250px;
  height:150px;

  .feather {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  .btn-reload {
    position: absolute;
    top:0;
    right:0;
    button {
      padding: 8px;
    }
    .feather {
      color: rgba(0,0,0,.3);
      margin-right: 0;
    }
  }
}


.inactive {
  color: rgba(0,0,0,.5);
}

.monitor {
  line-height: 1.5rem;
  .inactive {
    font-size: 1rem;
  }
}

dt {
  float: left ;
  clear: left ;
  margin-right: 0.5em ;
  width: 50px ;
}

dd {
  float: left ;
  margin-left: 1em ;
}
</style>