<template lang="pug">
  div.row.justify-content-center
    div.col-auto.mb-3
      UserCountCard
    div.col-auto.mb-3
      SalesCard
    //- div.col-auto.mb-3
      MonitorCountCard
    div.col-auto.mb-3
      MonitorV2TotalCountCard
    div.col-auto.mb-3
      MonitorV2CountCard(:group_id="1")
    div.col-auto.mb-3
      MonitorV2CountCard(:group_id="2")
    div.col-auto.mb-3
      MonitorV2CountCard(:group_id="3")
    div.col-auto.mb-3
      MonitorV2CountCard(:group_id="4")
    div.col-auto.mb-3
      MonitorV2CountCard(:group_id="5")
    div.col-12.mb-3
      MonitorChart
    div.col-12.col-xl-10.mb-3
      SalesChart
</template>

<script>
// @ is an alias to /src
import UserCountCard from '@/components/cards/UserCountCard.vue';
import SalesCard from '@/components/cards/SalesCard.vue';
import MonitorCountCard from '@/components/cards/MonitorCountCard.vue';
import MonitorV2TotalCountCard from '@/components/cards/MonitorV2TotalCountCard.vue';
import MonitorV2CountCard from '@/components/cards/MonitorV2CountCard.vue';
import SalesChart from '@/components/SalesChart.vue';
import MonitorChart from '@/components/MonitorChart.vue';

export default {
  name: 'Dash',
  components: {
    UserCountCard, SalesCard, MonitorCountCard, MonitorV2CountCard, MonitorV2TotalCountCard, SalesChart, MonitorChart
  },
  created (){
    this.$store.commit("monitor_v2_count_reset", []);
  },
}
</script>
