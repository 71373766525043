<template lang="pug">
.row.monitor-chart
  hr
  .col-12.text-center
    p.fs-5.mb-0 {{ date }}
  .col-4
    canvas#monitor-chart-all
  .col-4
    canvas#monitor-chart-no-proxy
  .col-4
    canvas#monitor-chart-proxy
  .col-4
    canvas#monitor-chart-headless
  .col-4
    canvas#monitor-chart-headful
  .col-4
    canvas#monitor-chart-static
  hr
</template>

<script>
export default {
  name: 'MonitorChart',
  data (){
    return {
      ctx: {
        all: null,
        no_proxy: null,
        proxy: null,
        headless: null,
        headful: null,
        static: null,
      },
      charts: {},
      date: "",
      labels: [],
      data: {
        all: {
          pages: [],
          percentages: []
        },
        no_proxy: {
          pages: [],
          percentages: []
        },
        proxy: {
          pages: [],
          percentages: []
        },
        headless: {
          pages: [],
          percentages: []
        },
        headful: {
          pages: [],
          percentages: []
        },
        static: {
          pages: [],
          percentages: []
        },
      }
    }
  },
  computed: {
    sums(){
      let result = [];
      for(let i=0;i<this.labels.length;i++)
        result.push(this.month[i]+this.year[i]);
      return result;
    }
  },
  mounted (){
    this.ctx.all = document.getElementById("monitor-chart-all");
    this.ctx.no_proxy = document.getElementById("monitor-chart-no-proxy");
    this.ctx.proxy = document.getElementById("monitor-chart-proxy");
    this.ctx.headless = document.getElementById("monitor-chart-headless");
    this.ctx.headful = document.getElementById("monitor-chart-headful");
    this.ctx.static = document.getElementById("monitor-chart-static");
    this.get();
  },
  methods: {
    get(){
      try {
        this.axios.get("/api/summary")
        .then((res)=>{
          this.date = res.data.date;
          this.labels = res.data.labels;
          this.data = res.data;
          this.draw("all", "全て");
          this.draw("no_proxy", "プロキシ以外");
          this.draw("proxy", "プロキシのみ");
          this.draw("headless", "Headless");
          this.draw("headful", "Headful");
          this.draw("static", "Static");
        });
      }
      catch(e){
        console.error(e);
      }
    },
    draw(key, title) {
      this.charts[key] = new Chart(this.ctx[key], {
        type: 'bar',
        data: {
          labels: this.labels,
          datasets: [
            {
              type: 'line',
              label: '比率',
              data: this.data[key].percentages,
              fill: true,
              tension: 0,
              backgroundColor: "rgba(54, 162, 235, 0.2)",
              borderColor: "rgba(54, 162, 235, 0.5)",
              yAxisID: 'yAxisPercentage'
            },
            {
              label: 'ページ数',
              data: this.data[key].pages,
              backgroundColor: 'rgba(219,39,91,0.5)',
              yAxisID: 'yAxisSites'
            },
          ]
        },
        options: {
          categoryPercentage: 1.1,
          title: {
            display: true,
            text: '売上'
          },
          scales: {
            yAxisSites: {
              type: 'linear',
              display: true,
              position: 'left',
              title: {
                display: true,
                text: 'ページ数'
              },
              grid: {
                drawOnChartArea: false,
              },
            },
            yAxisPercentage: {
              type: 'linear',
              display: true,
              position: 'right',
              title: {
                display: true,
                text: '比率（％）'
              }
            },
            x: {
              grid: {
                display: false
              },
              align: "end"
            }
          },
          plugins: {
            title: {
              display: true,
              text: title
            }
          },
        }
      });
    },
  }
}
</script>
